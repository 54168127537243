import { type UseFormReturn, type ErrorOption } from "react-hook-form";
import { parse, stringify } from "qs";
import { type ReadonlyURLSearchParams } from "next/navigation";

import { type TRPCClientError } from "@trpc/client";
import { type SearchParams } from "@/types";
import {
  type RouterOutputs,
  type AppRouter,
} from "@cloudifybiz/lighthouse-core/trpc/root";
import { type MediaFragment } from "@cloudifybiz/lighthouse-core/strapi/generated";
import { env } from "@/env.mjs";
import { getPublicImageURL } from "@cloudifybiz/lighthouse-core/utils/misc";

export const getIpAddress = (request: Request) => {
  let ip = request.headers.get("x-real-ip");
  const forwardedFor = request.headers.get("x-forwarded-for");
  if (!ip && forwardedFor) {
    ip = forwardedFor.split(",").at(0) ?? null;
  }
  return ip;
};

/**
 * Return an Intl.RelativeTimeFormat string without having to specify units.
 *
 * @param {Date} timestamp
 * @returns {string}
 */
export const getRelativeTime = (timestamp: Date) => {
  const units = {
    year: 31_557_600_000, // Approx. 86,400 seconds per day * 365.25 days.
    month: 2_629_800_000, // Approx. 31,557,600 seconds per year / 12 months.
    day: 86_400_000,
    hour: 3_600_000,
    minute: 60_000,
    second: 1_000,
  };

  const rtf = new Intl.RelativeTimeFormat("en", {
    numeric: "auto",
    style: "long",
  });

  const ms = timestamp.valueOf() - Date.now().valueOf();

  for (const [unit, value] of Object.entries(units)) {
    const amount = Math.ceil(ms / value);
    if (amount || unit === "second") {
      return rtf.format(amount, unit as Intl.RelativeTimeFormatUnit);
    }
  }
  return "Just Now";
};

/**
 * @description Get the first element of an array
 * @param array - The array to get the first element of
 * @returns The first element of the array
 */
export const getFirst = <T>(array: T[]) => {
  return array[0];
};

/**
 * @description Call a function after a given time
 * @param callback - The function to call
 * @param ms - The time to wait in milliseconds
 */
export const setTimeout = (callback: () => void, ms: number) => {
  if (ms < 0) {
    ms = 1 * 60 * 1000;
  }
  const id = window.setTimeout(callback, ms);
  return () => window.clearTimeout(id);
};

/**
 * @description Convert a TRPC error to a list of react-hook-form errors
 * @param error - The error to convert
 * @returns The list of form errors
 */
export const tRPCErrorToUseFormErrors = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends UseFormReturn<any>,
  S = Parameters<T["setError"]>[0],
>(
  error: unknown,
): Array<[S | "root" | `root.${string}`, ErrorOption]> => {
  if (error instanceof Error) {
    const { shape } = error as TRPCClientError<AppRouter>;
    if (shape?.data.zodError?.fieldErrors) {
      return Object.entries(shape.data.zodError.fieldErrors).map(
        ([key, value]) => {
          return [
            key as S,
            {
              type: "server",
              message: value?.join(", ") ?? "Unknown error",
            },
          ];
        },
      );
    } else if (shape?.data.message) {
      return [
        [
          "root",
          {
            type: "server",
            message: shape.data.message,
          },
        ],
      ];
    }
    return [
      [
        "root",
        {
          type: "server",
          message: error.message,
        },
      ],
    ];
  }
  return [["root", { type: "server", message: "Unknown error" }]];
};

/**
 * @description Get the value of a query parameter, if the query parameter is an array, the first element is returned
 * @param params - The query parameters
 * @param key - The key of the query parameter
 * @returns The value of the query parameter
 * @example
 * ```ts
 * getParam({ test: "test" }, "test");
 * // "test"
 * ```
 * @example
 * ```ts
 * getParam({ test: ["test1", "test2"] }, "test");
 * // "test1"
 * ```
 */
export const getParam = (params: SearchParams, key: string) => {
  const param = params[key];
  return Array.isArray(param) ? param[0] : param;
};

/**
 * @description Convert a list of user companies to a list of groups
 * @param userCompanies - The user companies to convert
 * @returns The list of groups
 */
export const userCompaniesToGroups = (
  userCompanies: RouterOutputs["user"]["companies"],
) => {
  return [
    {
      label: "Companies",
      companies: userCompanies.map((userCompany) => ({
        label: userCompany.company.name,
        value: userCompany.companySlug,
        role: userCompany.role,
        logo: userCompany.company.logo
          ? getPublicImageURL(userCompany.company.logo, "COMPANY_LOGO")
          : undefined,
      })),
    },
  ];
};

/**
 * @description Get the new path based on the current path if exists as well as the search parameters.
 * The next url is the current url with the search parameters.
 * If the current url is not provided, the search parameters are added to the destination url.
 * @example
 * ```ts
 * getNewPath({
 *  destination: "/auth/login",
 *  current: "/dashboard",
 *  searchParams: { test: "test" },
 * });
 *  // "/auth/login?next=/dashboard?test=test"
 * ```
 * @example
 * ```ts
 * getNewPath({
 * destination: "/auth/login",
 * searchParams: { test: "test" },
 * });
 * // "/auth/login?test=test"
 * ```
 * @param options - The options to use
 * @returns The formed url
 */
export const getNewPath = ({
  destination,
  current,
  searchParams,
}: {
  /** The destination url */
  destination: string;
  /** The current url */
  current?: string;
  /** The search parameters */
  searchParams?: SearchParams | URLSearchParams;
}) => {
  let searchParamsString = "";
  if (searchParams) {
    if ("getAll" in searchParams) {
      if ((searchParams as URLSearchParams).size > 0)
        searchParamsString = `?${(searchParams as URLSearchParams).toString()}`;
    } else {
      searchParamsString = stringifySearchParams(searchParams);
    }
  }
  // If we have a current url,
  // add it as the next url to the destination url otherwise add the search parameters
  return current
    ? `${destination}${stringifySearchParams({
        next: `${current}${searchParamsString}`,
      })}`
    : `${destination}${searchParamsString}`;
};

export const getStrapiMedia = (media: MediaFragment) => {
  if (!media.attributes?.url) return {};
  return {
    url: `${env.NEXT_PUBLIC_STRAPI_PUBLIC_URL}${media.attributes.url}`,
    alt: media.attributes.alternativeText,
  };
};

/**
 * @description Parse search parameters
 * @param str - The string to parse
 * @returns
 * @example
 * ```ts
 * parseSearchParams("?test=test");
 * // { test: "test" }
 * ```
 * @example
 * ```ts
 * parseSearchParams("?test[]=test1&test[]=test2");
 * // { test: ["test1", "test2"] }
 * ```
 * @example
 * ```ts
 * parseSearchParams({ test: "test", "test2[test3]": "test4" });
 * // { test: "test", test2: { test3: "test4" } }
 * ```
 */
export const parseSearchParams = (
  str: Parameters<typeof parse>[0] | SearchParams,
) =>
  // @ts-expect-error qs types are wrong, it will work with values that are arrays
  parse(str, {
    parseArrays: true,
  });

/**
 * @description Stringify search parameters
 * @param obj - The object to stringify
 * @returns
 * @example
 * ```ts
 * stringifySearchParams({ test: "test" });
 * // "?test=test"
 * ```
 * @example
 * ```ts
 * stringifySearchParams({ test: ["test1", "test2"] });
 * // "?test[]=test1&test[]=test2"
 * ```
 */
export const stringifySearchParams = (obj: Parameters<typeof stringify>[0]) =>
  stringify(obj, {
    addQueryPrefix: true,
    arrayFormat: "brackets",
  });

/**
 * @description Convert a file to base64
 * @param file - The file to convert
 * @param removeType - Whether to remove the type from the base64 string
 * @returns The base64 string
 */
export const toBase64 = (
  file: File,
  removeType: boolean = true,
): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      resolve(
        (removeType
          ? reader.result?.toString().split(",")[1]
          : reader.result?.toString()) || "",
      );
    reader.onerror = reject;
  });

export const createQueryString = (
  params: Record<string, string | number | null>,
  searchParams?: ReadonlyURLSearchParams,
) => {
  const newSearchParams = new URLSearchParams(searchParams?.toString());

  for (const [key, value] of Object.entries(params)) {
    if (value === null) {
      newSearchParams.delete(key);
    } else {
      newSearchParams.set(key, String(value));
    }
  }

  return newSearchParams.toString();
};
